import React from 'react';
import serverDown from './server_down.svg';
import './App.css';

function App() {
	return (
		<div className="maintenance">
			<div className="maintenance__message">
				<img class="maintenance__img" alt="server down" src={serverDown} />
				<h1>Sorry, we're down for scheduled maintenance right now</h1>
				<p>We expect to be back up in a few hours. Thanks for your patience.</p>
			</div>
		</div>
	);
}

export default App;
